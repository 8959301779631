@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF PRO";
  src: url("../src/fonts/SFPRODISPLAYREGULAR.OTF");
}

body {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-family: "SF PRO" !important;
  background-image: url(./bg.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

label,
div,
p,
span,
Th,
th,
tr,
Tr,
Td,
td,
h1,
h2,
h3,
h4,
h5 {
  font-family: "SF PRO" !important;
}

@media screen and (max-width: 768px) {
  body {
    background-image: url(./bg-mobile.png) !important;
    background-size: cover !important;
    background-repeat: no-repeat;
  }
}

.helperText {
  font-size: 11px;
  color: #b3b7be;
}

label {
  font-size: 14px;
}

input {
  font-size: 16px !important;
}

.brandcolor {
  color: #2b5fd0;
}



iframe {
  outline: 0 !important;
  border: 0 !important;
}

.iframe {
  width: 80vw !important;
  height: 90vh !important;
  margin: 20px 0px !important;
}